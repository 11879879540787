<template>
  <div class="mod-user">
    <div class="searchBox"  :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="企业信用代码">
          <el-input v-model="dataForm.creditCode" placeholder="企业信用代码" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户全称">
          <el-input v-model="dataForm.fullName" placeholder="客户全称" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户阶段" prop="stage">
          <el-select @change="getDataList" class="item-choose" v-model="dataForm.stage" filterable clearable placeholder="请选择">
            <el-option label="待跟进" :value="1"></el-option>
            <el-option label="跟进中" :value="2"></el-option>
            <el-option label="成交" :value="3"></el-option>
            <el-option label="流失" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跟进人">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.followId" size="small" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in userJobMap.SALE_DEP"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择">
            <el-option
                v-for="item in dictTypeMap.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对接人" prop="salesType">
          <el-select class="item-choose" v-model="dataForm.salesType" filterable clearable placeholder="请选择">
            <el-option label="业务员" :value="1"></el-option>
            <el-option label="业务经理" :value="2"></el-option>
            <el-option label="客服员" :value="3"></el-option>
            <el-option label="客服经理" :value="4"></el-option>
          </el-select>
          <el-form-item label="">
            <el-input v-model="dataForm.salesName" placeholder="对接人名称" clearable></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="newAuth('customer:manager:add')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button type="success" @click="addMarketTrack('add')">营销跟踪</el-button>
          <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table
          :data="dataList"
          height="72vh"
          :row-class-name="tableRowClassName"
          @row-click="rowClick"
          :row-style="selectedHighlight"
          border
          size="small"
          v-loading="dataListLoading"
          ref="tableRef"
          style="width: 100%;">

        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
            :class="item.styleClass"
            :class-name="item.styleClass"
            :cell-class-name="getCellClassName">
          <template slot-scope="scope">
            <!-- 状态 -->
            <template v-if="item.prop == 'status'">
              <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
              <el-tag v-else size="small">启用</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('customer:manager:find')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button>
              <el-button v-if="newAuth('customer:manager:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row.id)">修改</el-button>
              <el-button v-if="newAuth('customer:manager:enable')" type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
               <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗, 新增营销跟踪 -->
    <market-track v-if="addMarketTrackVisible" ref="marketTrack"></market-track>
    <!-- 弹窗详情 -->
    <detail v-if="detailVisible" ref="detail"></detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from '@/components/table'
import AddOrUpdate from './details/info-save'
import detail from './details/info-detail'
import viewImage from '@/views/commonPop/viewImage'
import qs from 'qs'
import marketTrack from './details/market-track'
import * as myJsonClon from "@/utils/myJsonClone";
export default {
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 39,
        configDefaultColumn: [
          {label: '企业信用代码',prop: 'creditCode',width: '150',check: true,fixed: undefined},
          {label: '客户全称',prop: 'fullName',width: '150',check: true,fixed: undefined},
          {label: '地址',prop: 'address',width: '150',check: true,fixed: undefined},
          {label: '行业',prop: 'industry',width: '80',check: true,fixed: undefined},
          {label: '主营业务',prop: 'business',width: '80',check: true,fixed: undefined},
          {label: '客户等级',prop: 'grade',width: '80',check: true,fixed: undefined,formatter: 'dictConvert'},
          {label: '客户阶段',prop: 'stage',width: '80',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '跟进人',prop: 'followName',width: '80',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '结算周期(天)',prop: 'settleCycle',width: '80',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '邮件跟踪(次)',prop: 'mailCount',width: '80',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '营销跟踪(次)',prop: 'trackCount',width: '80',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '关联报价模板',prop: 'templateName',check: true,fixed: undefined,formatter: 'stageTypeConvent'},
          {label: '状态',prop: 'status',check: true,fixed: 'right',formatter: 'stageTypeConvent'},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
        configDefaultPropColumnMap: new Map(),
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        customerCode: null,
        customerId: null,
        fullName: null,
        contactName: null,
        contactPhone: null,
        status: 1,
        customerIdList: [],
        followId: null,
        stage: null,
        creditCode: null,
        salesName: null,
        salesType: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      addMarketTrackVisible: false,
      detailVisible: false,
      stageTypeMap: null,
      userJobMap: new Map()
    }
  },
  components: {
    configTable,
    AddOrUpdate,
    detail,
    viewImage,
    marketTrack
  },
  activated () {
    this.initConfigTable()

    this.stageTypeMap = new Map()
    this.stageTypeMap.set(1, '待跟进')
    this.stageTypeMap.set(2, '跟进中')
    this.stageTypeMap.set(3, '成交')
    this.stageTypeMap.set(4, '流失')
    this.getDataList()
    this.getUserList()
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {
          this.config.configDefaultPropColumn.push({prop: x.prop})
          this.config.configDefaultPropColumnMap.set(x.prop,x)
        })
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    getCellClassName({ row, column, rowIndex, columnIndex }) {
      let newVar = this.config.configDefaultPropColumnMap.get(column.property);
      if (newVar && newVar.styleClass) {
        return newVar.styleClass;
      }
      return '';
    },
    // -----------------动态表格模式end-----------------

    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.id)
    },
    // 获取用户信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/customer/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      if (!this.newAuth('customer:manager:findAll')) {
        this.dataForm.customerId = this.currentUser.customerId
        this.dataForm.customerIdList = this.currentUser.customerIdList
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'id': this.dataForm.customerId,
        'customerCode': this.dataForm.customerCode,
        'fullName': this.dataForm.fullName,
        'status': this.dataForm.status,
        'contactName': this.dataForm.contactName,
        'contactPhone': this.dataForm.contactPhone,
        'ids': this.dataForm.customerIdList,
        'followId': this.dataForm.followId,
        'stage': this.dataForm.stage,
        'creditCode': this.dataForm.creditCode,
        'salesType': this.dataForm.salesType,
        'salesName': this.dataForm.salesName
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/customer/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    stageTypeConvent (row, column, cellValue, index) {
      return this.stageTypeMap.get(cellValue)
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 新增营销跟踪
    addMarketTrack (todo) {
      this.addMarketTrackVisible = true
      this.$nextTick(() => {
        this.$refs.marketTrack.init(todo)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/customer/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.my-table-cell) {
  background-color: antiquewhite!important;
}
:deep(.my-table-cell-v2) {
  background-color: beige!important;
}
:deep(.my-table-cell-v3) {
  background-color: aliceblue!important;
}
</style>

